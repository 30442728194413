import styled from 'styled-components';

const SectionWrapper = styled.div`
  padding: 150px 0 25px;
  overflow: hidden;
  @media (max-width: 1600px) {
    padding: 100px 0 0px;
  }
  @media only screen and (max-width: 1366px) {
    padding: 100px 0;
  }
  @media only screen and (max-width: 667px) {
    padding: 100px 0 0;
  }
`;

export default SectionWrapper;
