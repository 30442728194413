import React, { Fragment, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/SaasModern/sassModern.style';
import Navbar from '../containers/SaasModern/Navbar';

import Footer from '../containers/SaasModern/Footer';
import Agb from '../containers/SaasModern/AGB';
import Seo from '../components/seo';
import { useIntl } from 'gatsby-plugin-react-intl';
import languages from '../intl/languages';

const Terms = ({ location }) => {
  const [state, setState] = useState({
    navbarFixed: false,
  });

  const navbarFixed = state.navbarFixed;
  const intl = useIntl();

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          lang={intl.locale}
          title={intl.formatMessage({ id: 'page.terms.title' })}
          locales={languages}
          pathName={location.pathname}
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            onStateChange={status =>
              setState({
                navbarFixed: status.status === Sticky.STATUS_FIXED,
              })
            }
          >
            <DrawerProvider>
              <Navbar fixed={navbarFixed} />
            </DrawerProvider>
          </Sticky>
          <Agb />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default Terms;
